import { QueryClient } from 'react-query';

const {
  REACT_APP_BASE_URL,
  REACT_APP_PAYMENT_URL,
  REACT_APP_OMOTENASHI_BASE_URL,
  REACT_APP_ENCRYPTION_KEY,
  REACT_APP_ENV,
} = process.env;
export {
  REACT_APP_BASE_URL,
  REACT_APP_PAYMENT_URL,
  REACT_APP_OMOTENASHI_BASE_URL,
  REACT_APP_ENCRYPTION_KEY,
  REACT_APP_ENV,
};

// REACT QUERY CONFIG

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});
