import { Result } from 'antd';
import CustomButton from 'components/CustomButton';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

const chunkFailedMessage = /Loading chunk [\d]+ failed/;
function ErrorFallback({ message, error }) {
  const history = useHistory();

  function setWithExpiry(key, value, ttl) {
    const item = {
      value,
      expiry: new Date().getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  function getWithExpiry(key) {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }
  useEffect(() => {
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
        console.log('reloaded');
      }
    }
  }, [error]);
  if (chunkFailedMessage.test(error?.message))
    return (
      <div
        className="error-msg"
        style={{ height: message ? 'unset' : '100vh' }}
      >
        お待ち下さい
      </div>
    );

  return (
    <div className="error-msg" style={{ height: message ? 'unset' : '100vh' }}>
      <Result
        status="warning"
        title="操作に問題があります。"
        subTitle={message}
      />
      {message ? (
        <CustomButton onClick={() => history.goBack()}>
          前の画面に戻る
        </CustomButton>
      ) : (
        <CustomButton onClick={() => window.location.reload()}>
          一覧に戻る
        </CustomButton>
      )}
    </div>
  );
}

export default ErrorFallback;
