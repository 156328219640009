import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'components/LoadingSpinner';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { queryClient, REACT_APP_ENV } from 'config';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactQueryDevtools } from 'react-query/devtools';
import ErrorFallback from 'components/ErrorFallback';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// import App from "./App";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import registerServiceWorker from './serviceWorkerRegistration';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.min.css';
import 'react-datepicker/dist/react-datepicker.css';

const { NODE_ENV } = process.env;
const App = lazy(() => import('App'));

Sentry.init({
  dsn: 'https://a3afae6657aa4ee38a5b45fbe8d264c9@o428941.ingest.sentry.io/5908459',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  enabled: NODE_ENV !== 'development',
  tracesSampleRate: window.location.hostname.includes('umisachi.com') ? 0 : 1.0,
  environment: REACT_APP_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Spinner />}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <App />
          </ErrorBoundary>
        </QueryClientProvider>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// serviceWorkerRegistration.register();
registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
